import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Chromelpexpressmin from "src/components/chromelpexpressmin"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import Btf_cb_ez2 from "src/components/btf_cb_ez2"
import ClearCta1click from "src/components/clearCta1click"


const chromelpezData = import("./data/data.json");
const btfData = import("./data/btfData.json");

export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
       body {
         min-height:100vh;
         width: 100%;
         min-width: 728px;
         min-height: 700px;
         background: #E1EEF8;
         background: linear-gradient(to top right, #E1EEF8, #F7FCFF);
         padding: 0;
         margin: 0;
         background-repeat: no-repeat;
         background-size: cover;
         font-family: 'Roboto', sans-serif;
       }

       .footerlinks-module--br {
         display: none!important;
       }

       .Btf_cb_ez-module--btfCb button.Btf_cb_ez-module--install {
          border: none;
          background: #0078D4;
          color: #fff;
          font-weight: 500;
          font-size: 20pt;
          padding: 15px 50px;
          border-radius: 50px;
          cursor: pointer;
          letter-spacing: .5px;
          transition: .2s ease;
          margin: 25px auto;
          max-width: 300px;
       }

    `}
    </style>
    <style type="text/css">
         </style>
    <title>Print any web page easily - Easy Print</title></Helmet>
    <section>
      <Chromelpexpressmin data={chromelpezData}>
        <ClearCta1click></ClearCta1click>
      </Chromelpexpressmin>
      <Btf_cb_ez2 data={btfData}>
        <ClearCta1click></ClearCta1click>
      </Btf_cb_ez2>
      </section>
    </HomepageLayout>
  )
}
