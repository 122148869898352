import React from "react"
import {isChrome,isFirefox,isIeEdge} from "@tightrope/lpscripts/browserdetect"
import Styles from "./css/clearcta1click.module.scss"
class ClearCta1click extends React.Component {
  constructor(props){
    super(props);
    this.state = ({data: {}});
  }
  componentDidMount(){
    if(this.props.data){
      this.state = {
        data: this.props.data
      }
    }
  }
  render() {
    let browser = null;
    if(isChrome()){
      browser = 'chrome';
    }
    else if(isFirefox()){
      browser = 'firefox';
    }
    else{
      browser = 'other';
    }
    return (
        <div id = {Styles.clearcta1click}>
          <p>By downloading and installing Clear you acknowledge that you are an authorized user of this PC and have read and agree to the Clear <a href="https://clearbar.app/terms-of-service/" target="_blank" className={Styles.bold} >Terms of Service</a> and <a href="https://clearbar.app/privacy-policy/" target="_blank" className={Styles.bold} >Privacy Policy</a> that describes use of data. Clear is a Chromium based browser with web search results provided by Yahoo. Clear will start when you login. Visit the Clear website for help and how to uninstall it or to send feedback.</p>
        </div>
    )
  }
}
export default ClearCta1click
