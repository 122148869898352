import React from "react"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"
import Styles from "./css/chromelpexpressmin.module.scss"
import Data from "./data/chromelp.json"
import Footerlinks from "@tightrope/footerlinks/footer"


class chromelp extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        isChrome: isChrome(),
        isFirefox: isFirefox(),
        isIeEdge: isIeEdge(),
        display: 'none',
        pageData: props.data,
        data: Data
      }
    }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    if(this.props.data){
      this.props.data.then(data => {
        const instanceData = Object.assign(this.state.data, data);
        this.setState({ data: instanceData});
      this.state.isChrome = isChrome();
      this.state.isFirefox = isFirefox();
      this.state.isIeEdge = isIeEdge();

      if(isChrome()){
        const browserData = Object.assign(this.state.data, this.state.data.chrome);
        this.setState({data: browserData});
      }
      if(isFirefox()){
        const browserData = Object.assign(this.state.data, this.state.data.firefox);
        this.setState({data: browserData});
      }

      });
    }
  }
  render() {
    return (
      <>
      <div id = {Styles.chromelpexpressmin}>
        <div className = {Styles.header}><img src="/img/clear.png" alt="" /></div>
     <div className = {Styles.main}>
       <h1 className = "hc_param">{this.state.data.headline}</h1>

       <div className = {Styles.stepGrid}>
         <div className = {Styles.step}>1</div>
         <div className = {Styles.copy}>
           <h2>{this.state.data.subhead1}</h2>
           <p>{this.state.data.copy1}</p>
         </div>

         <div className = {Styles.step}>2</div>
         <div className = {Styles.copy}>
           <h2>{this.state.data.subhead2}</h2>
           <p>{this.state.data.copy2}</p>
         </div>
       </div>


       <form id = {Styles.expresstoggle} action="/action_page.php" className = {Styles.checkCircle}>
 			<h2>Install Options</h2>
 				<ul>
         <li>
            <label>
              <input type="checkbox" defaultChecked id = "winStartup" />
            </label>
            <span>Launch on Windows Startup</span>
          </li>
           <li>
            <label>
              <input type="checkbox" defaultChecked id = "desktopShrtct" />
              <span></span>
            </label>
            <span>Create Desktop Shortcut</span>
          </li>
 				</ul>
 			</form>

       <div className = {Styles.comp}>{this.state.data.compliance}</div>
       <button className = {Styles.download} data-cy="cta" onClick = {() => window.triggerInstall()}>{this.state.data.button}</button>

     {this.props.children}

       </div>
        </div>
      <Footerlinks></Footerlinks>
      </>
    )
  }
}
export default chromelp
